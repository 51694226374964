import { Controller } from "@hotwired/stimulus"
import * as formatter from "../src/js/format_helper"

export default class extends Controller {

  connect() {
    $(window).resize(function(event){ this.distributePips(); }.bind(this));
    setTimeout(function(){ this.distributePips(); }.bind(this), 200);
  }

  adjustSlidersPostions(element) {
    const pips = element.querySelectorAll('ul li')
    const slider = element.querySelector('input[type=range]')
    const pip_gap = (slider.clientWidth - 20) / (pips.length - 1)
    pips.forEach((pip_element, pip_index) => {
      const left = pip_index * pip_gap + 10 - (pip_element.clientWidth / 2.0)
      pip_element.style.left = `${left}px`
    })
  }

  distributePips() {
    // wait for 10 milliseconds before we start calculting the slider positions
    // this is to ensure that all sliders have the correct clientWidth before calculation
    setTimeout(() => this.adjustSlidersPostions(this.element), 500);
    // this.adjustSlidersPostions(this.element)
  }

}
