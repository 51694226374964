import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "dropdown"
  ]
  connect() {
    const dropdown = $(this.dropdownTarget)
  }

  disconnect() {
  }
}