import { Controller } from "@hotwired/stimulus"
import * as inputs from "../src/js/input_helper"
import * as formatter from "../src/js/format_helper"

export default class extends Controller {
  static targets = [
    "loanBalance",
    "loanExists",
    "websiteExists",
    "websiteUrl"
  ]

  connect() {
    this.showHideTotalLoanBalance(inputs.get_choice_from_radio_group(this.loanExistsTarget))
    this.showHideWebsiteUrl(inputs.get_choice_from_radio_group(this.websiteExistsTarget))
  }

  loanToggleChanged(ev) {
    this.showHideTotalLoanBalance(inputs.get_choice_from_radio_click(ev))
  }

  showHideTotalLoanBalance(choice) {
    this.loanBalanceTarget.parentElement.classList.toggle("d-none", !(choice == "1"))
  }

  loanBalanceChanged(e) {
    this.loanBalanceTarget.value = formatter.money(e.target.value)
  }

  websiteToggleChanged(ev) {
    this.showHideWebsiteUrl(inputs.get_choice_from_radio_click(ev))
  }

  showHideWebsiteUrl(choice) {
    this.websiteUrlTarget.parentElement.classList.toggle("d-none", !(choice == "1"))
  }
}
