import { Controller } from "@hotwired/stimulus"
import * as inputs from "../src/js/input_helper"
import * as utils from "../src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "capacityConfirmation"
  ]

  connect() {
    if(this.hasCapacityConfirmationTarget){
      utils.scrollToSection("capacity-confirmation")
    }
  }
}
