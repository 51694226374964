import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox","submitButton"]

  connect() {
  }

  checkboxClick(ev) {
    let checkboxMap = this.checkboxTargets.map((e) => (e.checked==true));
    if(checkboxMap.includes(false)){
      this.submitButtonTarget.disabled = true
      this.submitButtonTarget.classList.add("btn-secondary")
    } else {
      this.submitButtonTarget.disabled = false
      this.submitButtonTarget.classList.add("btn-primary")
    }
  }
}
