import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ ]

  open_url(ev) {
    const element=ev.target
    if ((element.tagName != "A") && (element.parentElement.tagName != "A")) {
      Turbo.visit(this.data.get("url"))
    }
  }

}

