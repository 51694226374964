import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    "imageInputField",
    "submitButton",
    "sharesCurrentAddress"
  ]

  connect() {
    this.submitButtonTarget.disabled = true
  }

  submitForm(event) {

    if(this.hasSharesCurrentAddressTarget) {
      let optionChecked = this.sharesCurrentAddressTarget.querySelectorAll("input[type=radio]:checked")

      if((this.imageInputFieldTarget.value != "") && (optionChecked.length == 1)) {
        this.submitButtonTarget.removeAttribute("disabled")
        this.submitButtonTarget.click()
      }
    }
    else if(this.imageInputFieldTarget.value != "") {
      this.submitButtonTarget.removeAttribute("disabled")
      this.submitButtonTarget.click()
    }
  }

}
