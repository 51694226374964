import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "section",
    "status"
   ]

  connect() {
    if (this.statusTarget.dataset.activeBankStatementRequestCommunication == "true") {
      this.startPolling()
    }
  }

  disconnect() {
    this.stopPolling()
  }

  startPolling() {
    this.pollingInterval = setInterval(() => {
      this.fetchCommunicationStatusData()
    }, 10000)
  }

  stopPolling() {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval)
    }
  }

  fetchCommunicationStatusData() {
    const communicationUrl = this.sectionTarget.dataset.url

    fetch(communicationUrl, {
      method: 'GET',
      headers: {
                 "Content-Type": "application/json",
                 "Accept": "application/json"
              },
    }).then(response => response.json())
      .then(data => {
        this.loadContent(data)
      })
      .catch(error => {
        console.error(error)
      })
  }

  loadContent(data) {
    if (data.reload_required) {
      Turbo.visit(window.location)
    }

    this.sectionTarget.innerHTML = data.htmlPartial

    if (this.statusTarget.dataset.activeBankStatementRequestCommunication != "true") {
      this.stopPolling()
    }
  }
}
