import { Controller } from "@hotwired/stimulus"
import * as inputs from "../src/js/input_helper"
import * as utils from "../src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "typeSelection",
    "typeDependentField",
  ]

  connect() {
    inputs.set_active_class_on_radios(this.typeSelectionTarget)
    this.showHideBasedOnTypeSelection(Number(inputs.get_choice_from_radio_group(this.typeSelectionTarget)))
  }

  typeChanged(ev) {
    this.showHideBasedOnTypeSelection(Number(inputs.get_choice_from_radio_click(ev)))
  }

  showHideBasedOnTypeSelection(choice) {
    this.typeDependentFieldTargets.forEach(e => {
      e.classList.toggle('d-none', !(e.dataset.for == choice))
    })
  }

}
