import { Controller } from "@hotwired/stimulus"
import * as inputs from "../src/js/input_helper"
import * as utils from "../src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "selectedOption",
    "ebs",
    "replacementCredit",
    "lowDocPropertyOwner",
    "lowDocNonPropertyOwner",
    "fullFinancials"
  ]

  connect() {
    this.handleOption()
  }

  optionChanged() {
    this.handleOption()
    this.element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  }

  handleOption() {
    const selected = inputs.get_choice_from_radio_group(this.selectedOptionTarget)
    const optionSections = [
      this.ebsTargets,
      this.replacementCreditTargets,
      this.lowDocPropertyOwnerTargets,
      this.lowDocNonPropertyOwnerTargets,
      this.fullFinancialsTargets
    ]
    // Note: we deliberately show the selected option before hiding the others to avoid the screen jumping around too much
    optionSections.forEach(optionTargets => { optionTargets.forEach(target => { if (target.dataset.value == selected) { utils.show(target) } }) })
    optionSections.forEach(optionTargets => { optionTargets.forEach(target => { if (target.dataset.value != selected) { utils.hide(target) } }) })
  }
}
