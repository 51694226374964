import { Controller } from "@hotwired/stimulus"
import * as inputs from "../src/js/input_helper"
import * as utils from "../src/js/stimulus_helper"

export default class extends Controller {
  static targets = [ "statusSelection", "statusDependentField", "occupancyStatus"]

  connect() {
    if (utils.readonly()) {
      this.show()
    } else {
      this.edit()
    }
  }

  edit() {
    $(this.statusSelectionTarget).on('change', (ev) => this.statusChanged(ev))
    this.changeStatus(this.statusSelectionTarget.value)
  }

  show() {
    this.changeStatus(this.statusSelectionTarget.value)
  }

  statusChanged(event) {
    this.changeStatus(event.target.value)
  }

  changeStatus(selection) {
    this.occupancyStatusTargets.forEach(elem => {
      const parent = elem.closest('.occupancy-status-parent')
      // enable or disable
      if(!parent) console.error("no parent found for",elem);
      if ( !utils.readonly() && (parent.dataset.for && parent.dataset.for.split(',').includes(selection)) ) {
        elem.disabled = false
      } else {
        elem.disabled = true
      }
    })
    // show or hide
    this.statusDependentFieldTargets.forEach(e => {
      e.classList.toggle('d-none', !(e.dataset.for.split(',').includes(selection)))
    })
  }

}
