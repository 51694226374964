import { Controller } from "@hotwired/stimulus"
import * as utils from "../src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "uploaderField"
  ]

  connect() {
    this.showOneBlankInputField()
  }

  uploadedFile(ev) {
    this.showOneBlankInputField()
  }

  showOneBlankInputField() {
    var foundBlank = false

    this.uploaderFieldTargets.forEach(uploaderField =>  {
      uploaderField.querySelectorAll("input[type=file]").forEach(inputField => {
        if (inputField.value)
          utils.show(uploaderField)
        else if (!foundBlank) {
          utils.show(uploaderField)
          foundBlank = true
        }
      })
    })
  }
}
