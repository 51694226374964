import { Controller } from "@hotwired/stimulus"
import * as inputs from "../src/js/input_helper"
import * as utils from "../src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "beneficiariesIdentifiedByClass",
    "detailsOfClass"
  ]

  connect() {
    this.toggleDetailsOfClass(inputs.get_choice_from_radio_group(this.beneficiariesIdentifiedByClassTarget))
  }

  beneficiariesIdentifiedByClassChanged(e) {
    this.toggleDetailsOfClass(inputs.get_choice_from_radio_click(e))
  }

  toggleDetailsOfClass(choice) {
    const hide = choice != "1"
    utils.toggle(this.detailsOfClassTarget, hide)
  }
}
