import { Controller } from "@hotwired/stimulus"
import * as utils from "../src/js/stimulus_helper"

export default class extends Controller {

  connect() {
    window.setTimeout(e => this.bodyScrolled(null), 100)
    window.addEventListener("scroll", ev => this.bodyScrolled())
    window.addEventListener("resize", ev => this.bodyScrolled())
    document.addEventListener("DOMSubtreeModified", ev => this.bodyScrolled())
    document.addEventListener("turbo:load", ev => this.pageLoad())
    document.addEventListener("turbolinks:load", ev => this.pageLoad())
    this.pageLoad()
  }

  bodyScrolled() {
    const scrollArrowElement = document.getElementById("scroll-more-text")
    if (scrollArrowElement) {
      const bodyBottom = document.body.offsetTop + document.body.offsetHeight
      const windowBottom = window.pageYOffset + window.innerHeight
      scrollArrowElement.classList.toggle("d-none", (bodyBottom - windowBottom < 16))
    }
  }

  pageLoad() {
    // Zero delay setTimeout schedules the call right after the current script is complete
    setTimeout(function(){
      document.querySelectorAll('form[data-readonly=true],div.readonly').forEach(form => {
        utils.disable_tags('input', form)
        utils.disable_tags('select', form)
        utils.disable_tags('textarea', form)
        utils.disable_tags_by_class('btn-group', form)
        utils.disable_tags_by_class('form-control-element', form)
      })
    })
  }
}
