import { Controller } from "@hotwired/stimulus"
import * as utils from "../src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "viewCodeButton",
    "code",
  ]

  viewCode(e) {
    e.preventDefault()
    utils.hide(this.viewCodeButtonTarget)
    utils.show(this.codeTarget)
    return false
  }
}
