import { Controller } from "@hotwired/stimulus"
import * as inputs from "../src/js/input_helper"
import * as utils from "../src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "applicantType",
    "soleTraderSection",
    "companySection",
    "corporateTrustSection",
    "partnershipSection",
  ]

  connect() {
    this.updateBasedOnApplicantType()
  }

  applicantTypeChanged(e) {
    this.updateBasedOnApplicantType()
  }

  updateBasedOnApplicantType() {
    const choice = inputs.get_choice_from_radio_group(this.applicantTypeTarget)
    utils.hide(this.soleTraderSectionTarget)
    utils.hide(this.companySectionTarget)
    utils.hide(this.corporateTrustSectionTarget)
    utils.hide(this.partnershipSectionTarget)

    switch (choice) {
      case 'sole_trader':
        utils.show(this.soleTraderSectionTarget)
        break
      case 'company':
        utils.show(this.companySectionTarget)
        break
      case 'corporate_trust':
        utils.show(this.corporateTrustSectionTarget)
        break
      case 'partnership':
        utils.show(this.partnershipSectionTarget)
        break
    }
  }
}
