import { Controller } from "@hotwired/stimulus"
import * as inputs from "../src/js/input_helper"
import * as utils from "../src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "vehicleLookupMethod",
    "glassGuideLookupSection",
    "nevdisWithVinLookupSection",
    "nevdisWithRegoLookupSection",
    "usageHistory",
    "odometerSection",
    "insuranceFinanced",
    "odometer",
    "fittedOption",
    "fittedOptionSection",
    "accessoriesIncluded",
    "accessoriesDescription"
  ]

  connect() {
    this.handleVehicleHistory(inputs.get_choice_from_radio_group(this.usageHistoryTarget))
    this.handleFittedOption(inputs.get_choice_from_radio_group(this.fittedOptionTarget))
    this.handleAccessoriesIncluded(inputs.get_choice_from_radio_group(this.accessoriesIncludedTarget))
    this.updateCheckboxLabels()
    this.updateBasedOnVehicleLookupMethod()
  }

  vehicleLookupMethodChanged() {
    this.updateBasedOnVehicleLookupMethod()
  }

  updateBasedOnVehicleLookupMethod() {
    const vehicleLookupMethod = inputs.get_choice_from_radio_group(this.vehicleLookupMethodTarget)
    utils.hide(this.glassGuideLookupSectionTarget)
    utils.hide(this.nevdisWithVinLookupSectionTarget)
    utils.hide(this.nevdisWithRegoLookupSectionTarget)

    switch (vehicleLookupMethod) {
      case 'glass':
        utils.show(this.glassGuideLookupSectionTarget)
        break
      case 'nevdis_vin':
        utils.show(this.nevdisWithVinLookupSectionTarget)
        break
      case 'nevdis_rego':
        utils.show(this.nevdisWithRegoLookupSectionTarget)
        break
    }
  }

  updateCheckboxLabels() {
    this.element.querySelectorAll('input[type=checkbox]:checked').forEach(e => {
      if (e.closest('label') != null) {
        e.closest('label').classList.add('active')
      }
    })
  }

  vehicleHistoryChanged(ev) {
    this.handleVehicleHistory(inputs.get_choice_from_radio_click(ev))
  }

  handleVehicleHistory(choice) {
    this.odometerSectionTarget.classList.toggle("d-none", !this.require_used_vehicle_information(choice))
  }

  require_used_vehicle_information(usage_history) {
    return (usage_history == "used" || usage_history == "demo")
  }

  get options_url() {
    return this.data.get("options-url")
  }

  vehicleFittedOption(ev) {
    this.handleFittedOption(inputs.get_choice_from_radio_click(ev))
  }

  handleFittedOption(choice) {
    this.fittedOptionSectionTarget.classList.toggle("d-none", (choice != 1))
  }

  vehicleUsage(ev) {
    this.handleVehicleUsage(inputs.get_choice_from_radio_click(ev))
  }

  handleVehicleUsage(choice) {
    if (this.usageTarget) {
      this.usageTarget.classList.toggle("d-none", (choice != 1))
    }
  }

  accessoriesIncludedChanged(ev) {
    this.handleAccessoriesIncluded(inputs.get_choice_from_radio_click(ev))
  }

  handleAccessoriesIncluded(choice) {
    this.accessoriesDescriptionTarget.classList.toggle("d-none", (choice != 1))
  }
}

