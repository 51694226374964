import { Controller } from "@hotwired/stimulus"
import * as inputs from "../src/js/input_helper"
import * as utils from "../src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "occupiedPropertyFields",
    "occupiedPropertyAddress",
    "occupiedPropertyStatus",
    "propertyOwnershipFields",
    "propertyOwnerSelection",
    "ownedPropertyAddressSelection",
    "otherAddressFields",
    "propertyValueAndMortgageFields",
    "propertyValue",
    "mortgageBalance",
  ]

  connect() {
    this.handleOwnership()
  }

  optionChanged() {
    this.handleOwnership()
    //this.element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  }

  handleOwnership() {
    const selectedAddrIndex = this.ownedPropertyAddressSelectionTarget.selectedIndex
    const selectedAddressOption = this.ownedPropertyAddressSelectionTarget.options[selectedAddrIndex]

    if (!selectedAddressOption.value) {
      utils.hide(this.propertyOwnerSelectionTarget)
      utils.hide(this.otherAddressFieldsTarget)
      utils.hide(this.propertyValueAndMortgageFieldsTarget)
    } else if (selectedAddressOption.value == "other") {
      utils.show(this.propertyOwnerSelectionTarget)
      utils.show(this.otherAddressFieldsTarget)
      utils.show(this.propertyValueAndMortgageFieldsTarget)
      utils.show(this.propertyValueTarget)
      utils.show(this.mortgageBalanceTarget)
    } else {
      utils.show(this.propertyOwnerSelectionTarget)
      utils.hide(this.otherAddressFieldsTarget)
      utils.show(this.propertyValueAndMortgageFieldsTarget)
      const selectedAddressAndStatus = this.getOccupiedPropertyStatuses().find(addr_st => { return(addr_st[0] == selectedAddressOption.text) })
      if (selectedAddressAndStatus) {
        switch (selectedAddressAndStatus[1]) {
          case "mortgaged":
            utils.show(this.propertyValueTarget)
            utils.hide(this.mortgageBalanceTarget)
            break
          case "owned":
            utils.hide(this.propertyValueTarget)
            utils.hide(this.mortgageBalanceTarget)
            break
          default:
            utils.show(this.propertyValueTarget)
            utils.show(this.mortgageBalanceTarget)
        }
      } else {
        utils.show(this.propertyValueTarget)
        utils.show(this.mortgageBalanceTarget)
      }
    }
  }

  getOccupiedPropertyStatuses() {
    return(
      this.occupiedPropertyFieldsTargets.map(re => { 
        const addrElement = this.occupiedPropertyAddressTargets.find(addr => { return(re.contains(addr)) })
        const statusElement = this.occupiedPropertyStatusTargets.find(st => { return(re.contains(st)) })
        if (addrElement && statusElement) {
          return([ addrElement.textContent, statusElement.value ])
        }
      })
    )
  }
}
