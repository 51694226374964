import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'image' ]

  rotate(ev) {
    const angle = (parseInt(this.imageTarget.dataset.angle) || 0) + 90
    this.imageTarget.style.transform = 'rotate(' + angle + 'deg)'
    this.imageTarget.dataset.angle = angle
  }

}

