import { Controller } from "@hotwired/stimulus"
import * as utils from "../src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "startSigningButton",
    "generateContractButton",
    "signingMethod",
    "continueSigningButton",
    "signingStatus"
  ]

  connect() {
    this.handleSigningMethod("electronic")
    if (this.hasSigningMethodTarget) {
      $(this.signingMethodTarget).on('select2:select', (ev) => this.signingMethodChanged(ev.target))
      this.handleSigningMethod(this.signingMethodTarget.value)
    }
  }

  signingMethodChanged(target) {
    this.handleSigningMethod(target.value)
  }

  handleSigningMethod(signingMethod) {
    if (this.signingStatusTarget.textContent == 'partially_signed') {
      utils.show(this.continueSigningButtonTarget)
      utils.hide(this.startSigningButtonTarget)
      utils.hide(this.generateContractButtonTarget)
    } else {
      if (signingMethod == 'electronic') {
        utils.show(this.startSigningButtonTarget)
        utils.hide(this.generateContractButtonTarget)
        utils.hide(this.continueSigningButtonTarget)
      } else {
        utils.show(this.generateContractButtonTarget)
        utils.hide(this.startSigningButtonTarget)
        utils.hide(this.continueSigningButtonTarget)
      }
    }

  }

}
