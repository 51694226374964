import { Controller } from "@hotwired/stimulus"
import * as inputs from "../src/js/input_helper"
import csrfToken from "../src/js/csrf_token"
import * as utils from "../src/js/stimulus_helper"


export default class extends Controller {
  static targets = [
    "numberOfItems",
    "item0",
    "item1",
    "item2",
    "item3",
    "item4",
    "item5",
    "item6",
    "item7",
    "item8",
    "item9"
  ]

  connect() {
    inputs.set_active_class_on_radios(this.numberOfItemsTarget)
    this.showHideBasedOnnumberOfItems(Number(inputs.get_choice_from_radio_group(this.numberOfItemsTarget)))
  }

  numberOfItemsChanged(ev) {
    const choice = inputs.get_choice_from_radio_click(ev)
    if (choice) {
      this.showHideBasedOnnumberOfItems(Number(choice))
    }
  }

  showHideBasedOnnumberOfItems(n_properties) {
    if (this.hasItem0Target) { this.item0Target.classList.toggle('d-none', n_properties < 1) }
    if (this.hasItem1Target) { this.item1Target.classList.toggle('d-none', n_properties < 2) }
    if (this.hasItem2Target) { this.item2Target.classList.toggle('d-none', n_properties < 3) }
    if (this.hasItem3Target) { this.item3Target.classList.toggle('d-none', n_properties < 4) }
    if (this.hasItem4Target) { this.item4Target.classList.toggle('d-none', n_properties < 5) }
    if (this.hasItem5Target) { this.item5Target.classList.toggle('d-none', n_properties < 6) }
    if (this.hasItem6Target) { this.item6Target.classList.toggle('d-none', n_properties < 7) }
    if (this.hasItem7Target) { this.item7Target.classList.toggle('d-none', n_properties < 8) }
    if (this.hasItem8Target) { this.item8Target.classList.toggle('d-none', n_properties < 9) }
    if (this.hasItem9Target) { this.item9Target.classList.toggle('d-none', n_properties < 10) }
  }

  remove(e) {
    const elem = e.target
    const removalUrl = elem.dataset.removalUrl
    const confirmed = confirm(elem.dataset.confirmMessage)
    if ( confirmed == true ) {
      $.ajax({
        url: removalUrl,
        method: 'delete',
        headers: {"X-CSRF-Token": csrfToken()}
      })
      .done(function(){
        location.reload()
      })
      .fail(function(jqXHR, textStatus, errorThrown){
        alert("The requested action failed. Please reload the page and try again")
      })
    }
  }
}
