import { Controller } from "@hotwired/stimulus"
import * as inputs from "../src/js/input_helper"
import * as utils from "../src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "hasOtherLiabilitiesSelection",
    "otherLiabilityDependentField",
  ]

  connect() {
    this.showHideBasedOnhasOtherLiabilitiesSelection(inputs.get_choice_from_radio_group(this.hasOtherLiabilitiesSelectionTarget))
  }

  hasOtherLiabilitiesSelectionChanged(ev) {
    this.showHideBasedOnhasOtherLiabilitiesSelection(inputs.get_choice_from_radio_click(ev))
  }

  showHideBasedOnhasOtherLiabilitiesSelection(choice) {
    utils.toggleDependentFields(this.otherLiabilityDependentFieldTargets, choice)
  }

}
