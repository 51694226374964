import { Controller } from "@hotwired/stimulus"
import * as inputs from "../src/js/input_helper"
import * as utils from "../src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "form",
    "dropDownCriteria",
    "noOfRecords",
    "resetResult",
    "timeZone"
  ]

  connect() {
    const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.timeZoneTarget.value = time_zone;
    this.dropDownCriteriaTargets.forEach(item => {
      $(item).on('select2:select', (e)=> this.clear_result_list())
      $(item).on('select2:unselect', (e)=> this.clear_result_list())
    })

    if (this.hasResetResultTarget){
      utils.show(this.resetResultTarget)
    }
  }

  export_csv(e) {
    const params=new URLSearchParams(new FormData(this.formTarget))
    const base_url=window.location.href.split('?')[0];
    window.open(base_url + ".csv?" + params.toString())
    e.preventDefault()
  }

  clear_result_list(){
    if (this.hasResetResultTarget){
      utils.hide(this.resetResultTarget)
      this.noOfRecordsTarget.innerText = "";
    }
  }

}
