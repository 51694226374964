import { Controller } from "@hotwired/stimulus"
import * as inputs from "../src/js/input_helper"
import * as utils from "../src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "metBorrower",
    "consentsProcessSelection",
    "sendConsentsSection",
    "sendPrivacyConsentButton",
    "sendPrivacyConsentAndIdentificationButton"
  ]

  connect() {
    this.toggleConsentsOnlyOption(inputs.get_choice_from_radio_group(this.metBorrowerTarget))
    if (this.hasSendConsentsSectionTarget) {
      this.toggleConsentsProcessSelection(inputs.get_choice_from_radio_group(this.consentsProcessSelectionTarget))
    }
  }

  clickHasMetBorrower(e) {
    const hasMetBorrower = inputs.get_choice_from_radio_click(e)
    this.toggleConsentsOnlyOption(hasMetBorrower)
  }

  toggleConsentsOnlyOption(choice) {
    const consentOnlyRadio = this.consentsProcessSelectionTarget.querySelector('input[type=radio][value="privacy_only"]')
    if (choice == "1") {
      utils.show(consentOnlyRadio.parentElement)
    } else {
      utils.hide(consentOnlyRadio.parentElement)
      consentOnlyRadio.parentElement.classList.remove("active")
      const consentsProcessSelected = inputs.get_choice_from_radio_group(this.consentsProcessSelectionTarget)
      if (consentsProcessSelected == "privacy_only") {
        this.toggleConsentsProcessSelection(null)
      }
    }
  }

  changeConsentsProcessSelection(e) {
    this.toggleConsentsProcessSelection(inputs.get_choice_from_radio_click(e))
  }

  toggleConsentsProcessSelection(consentsProcessSelected) {
    if (consentsProcessSelected){
      if (consentsProcessSelected.includes("privacy")) {
        utils.show(this.sendConsentsSectionTarget)
        this.toggleSendSmsButton(consentsProcessSelected)
      } else {
        utils.hide(this.sendConsentsSectionTarget)
      }
    }
  }

  toggleSendSmsButton(e) {
    if (e == "privacy_only") {
      utils.show(this.sendPrivacyConsentButtonTarget)
      utils.hide(this.sendPrivacyConsentAndIdentificationButtonTarget)
    } else {
      utils.hide(this.sendPrivacyConsentButtonTarget)
      utils.show(this.sendPrivacyConsentAndIdentificationButtonTarget)
    }
  }

}
