import { Controller } from "@hotwired/stimulus"
import * as inputs from "../src/js/input_helper"
import * as utils from "../src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "checkbox",
    "acceptBtn"
  ]

  connect() {
  }

  validateCheckbox() {
    const unchecked = this.checkboxTargets.filter(checkbox => !checkbox.checked)
    if (unchecked.length == 0) {
      utils.enable(this.acceptBtnTarget)
    } else {
      utils.disable(this.acceptBtnTarget)
    }
  }
}
