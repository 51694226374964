import { Controller } from "@hotwired/stimulus"
import * as utils from "../src/js/stimulus_helper"

export default class extends Controller {
  connect() {
    document.querySelectorAll("input,select").forEach(errorField => {
      errorField.addEventListener("focus", utils.scrollToEventTarget)
    })
    $(document).on("focus", "span.select2-selection", utils.scrollToEventTarget)
    window.setTimeout(e => this.revealErrors(), 200)
  }

  revealErrors() {
    var firstErrorField = null
    document.querySelectorAll(".field_with_errors").forEach(errorField => {
      this.expose(errorField)
      firstErrorField = firstErrorField || errorField
    })

    if (firstErrorField) {
      utils.scrollToElement(firstErrorField)
    }
  }


  expose(element) {
    element.classList.remove("d-none")
    if (element.classList.contains("collapse") && !element.classList.contains("show")) {
      element.classList.forEach(className => {
        if (className != "collapse") {
          let header = document.querySelector('[data-target=".' + className + '"]')
          if (header) {
            header.click()
          }
        }
      })
    }
    if (element.parentElement) {
      this.expose(element.parentElement)
    }
  }
}
