import { Controller } from "@hotwired/stimulus"
import * as utils from "../src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "button",
    "signInButton",
    "chevronRight",
    "chevronDown"
  ]

  resolve(e) {
    e.preventDefault()
    utils.toggleVisibility(this.chevronRightTarget)
    utils.toggleVisibility(this.chevronDownTarget)
    utils.toggleVisibility(this.buttonTarget)
    return false
  }

  handleOptCodeChange(e) {
    if (/^[0-9]{6}$/.test(e.target.value)) {
      // Submit for form once receiving 6 digits opt code
      this.signInButtonTarget.click()
    }

  }
}
