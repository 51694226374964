import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "dateDiv"
  ]

  connect() {
    const dateDiv = $(this.dateDivTarget);
    const dateInput = dateDiv.find("input");
    const currentValue = dateInput.attr("value");
    const minDate = dateInput.attr("min");
    const maxDate = dateInput.attr("max");

    const dateOptions = {
      allowInputToggle: true,
      keepInvalid: true,
      viewMode: dateDiv.data("view-mode") || "months",
      format: dateDiv.data("format") || "DD/MM/YYYY",
      ...this.#dateValueOptions(currentValue, minDate, maxDate),
    };

    dateDiv.datetimepicker("destroy");
    dateDiv.datetimepicker(dateOptions);
    if (!this.#isCurrentValueValid(currentValue)) {
      this.#showInvalidDateToUser(dateInput, currentValue);
    }
  }

  #getMomentFromDate(date) {
    if (date && date.search(/[1,2]\d{3}-[01]\d-[0-3]\d$/) == 0) {
      return moment(date, "YYYY-MM-DD");
    } else if (date && date.search(/[0-3]?\d\/[01]?\d\/[1,2]\d{3}/) == 0) {
      return moment(date, "DD/MM/YYYY");
    } else {
      return null;
    }
  }

  /*
    datetimepicker("destroy") clears out the input value, but when an invalid date
    is passed back from the server (because the user has entered it wrong in the
    first place and we want to allow them the chance to correct it) tempusdominus
    never shows the invalid value again. Even using the "keepInvalid" setting
    doesn't restore the value.

    So we need to manually restore the invalid date so the user has a chance to
    correct it.
  */
  #showInvalidDateToUser(dateInput, invalidDateString) {
    dateInput.val(invalidDateString);
  }

  #isCurrentValueValid(currentValue) {
    let currentValueAsDate = this.#getMomentFromDate(currentValue);
    return currentValue && currentValueAsDate && currentValueAsDate.isValid();
  }

  #dateValueOptions(currentValue, minDate, maxDate) {
    let dateOptions = {};
    if (this.#isCurrentValueValid(currentValue)) {
      dateOptions["defaultDate"] = this.#getMomentFromDate(currentValue);
      dateOptions["date"] = this.#getMomentFromDate(currentValue);
    }
    if (minDate) {
      dateOptions["minDate"] = this.#getMomentFromDate(minDate);
    }
    if (maxDate) {
      dateOptions["maxDate"] = this.#getMomentFromDate(maxDate);
    }
    return dateOptions;
  }
}
