import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "companyNameSelect",
    "employmentStatusSelect",
    "companyNameFreeField",
    "employerWithoutAbn"
  ]

  connect() {
    this.toggleEmployerNameFields(this.employerWithoutAbnTarget.checked)
    this.employmentStatusChanged()
  }

  abnAttributeChanged() {
    this.toggleEmployerNameFields(this.employerWithoutAbnTarget.checked)
  }

  toggleEmployerNameFields(employerWithoutAbn) {
    this.companyNameSelectTarget.classList.toggle('d-none', employerWithoutAbn)
    this.companyNameFreeFieldTarget.classList.toggle('d-none', !employerWithoutAbn)
  }

  employmentStatusChanged() {
    if(this.hasEmploymentStatusSelectTarget) {
      if (this.employmentStatusSelectTarget.value == 'self_employed') {
        this.toggleEmployerNameFields(false)
        this.employerWithoutAbnTarget.disabled = true
        this.employerWithoutAbnTarget.checked = false
      } else{
        this.employerWithoutAbnTarget.disabled = false
      }
    }
  }
}
