import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "frequencySelection",
    "frequencyDependentField"
  ]

  connect() {
    this.showHideDirectDebitDetailField(this.frequencySelectionTarget.value)
  }

  frequencyChanged(event) {
    this.showHideDirectDebitDetailField(this.frequencySelectionTarget.value)
  }

  showHideDirectDebitDetailField(choice) {
    this.frequencyDependentFieldTargets.forEach(e => {
      e.classList.toggle('d-none', !(e.dataset.for.split(',').includes(choice)))
    })
  }
}
