import { Controller } from "@hotwired/stimulus"
import * as inputs from "../src/js/input_helper"
import * as utils from "../src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "noMedicareDetails",
    "medicareCardSection",
    "greenMedicareCardSection",
    "blueMedicareCardSection",
    "yellowMedicareCardSection",
    "medicareCardColour"
  ]

  connect() {
    const colour = this.data.get("colour")
    this.render(colour)
  }

  render(colour) {
    const noMedicardCard = this.noMedicareDetailsTarget.checked
    this.hideAllCards()
    if (!noMedicardCard) {
      utils.show(this.medicareCardColourTarget)
      switch(colour){
        case "B":
          utils.show(this.blueMedicareCardSectionTarget)
          break
        case "Y":
          utils.show(this.yellowMedicareCardSectionTarget)
          break
        default:
          utils.show(this.greenMedicareCardSectionTarget)
          break
      }
    }

  }

  hideAllCards() {
    this.medicareCardSectionTargets.forEach(target => utils.hide(target))
    utils.hide(this.medicareCardColourTarget)
  }

  medicareCardColourChanged(ev){
    if (ev.target.classList.contains("btn")) {
      this.render(inputs.get_choice_from_radio_click(ev))
    }
  }

  noMedicareDetailsChanged(ev) {
    if (ev.target.checked) {
      this.hideAllCards()
    } else {
      this.render('G')
    }
  }
}
