import { Controller } from "@hotwired/stimulus"
import * as inputs from "../src/js/input_helper"
import * as utils from "../src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "intendedUse",
    "privateUseSection",
    "businessUseSection",
  ]

  connect() {
    this.updateBasedOnIntendedUse()
  }

  intendedUseChanged(e) {
    this.updateBasedOnIntendedUse()
  }

  updateBasedOnIntendedUse() {
    const intendedUse = inputs.get_choice_from_radio_group(this.intendedUseTarget)
    utils.hide(this.privateUseSectionTarget)
    utils.hide(this.businessUseSectionTarget)

    switch (intendedUse) {
      case 'business':
        utils.show(this.businessUseSectionTarget)
        break
      case 'private':
        utils.show(this.privateUseSectionTarget)
        break
    }
  }
}
