import { Controller } from "@hotwired/stimulus"
import * as utils from "../src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "typeSelection",
    "typeDependentField"
  ]

  connect() {
    if (utils.readonly()) {
      this.show()
    } else {
      this.edit()
    }
  }

  edit() {
    this.showHideAssetsLiabilitiesField(this.typeSelectionTarget)
    this.changeStatus(this.typeSelectionTarget.value)
  }

  typeChanged(event) {
    this.changeStatus(event.target.value)
  }

  showHideAssetsLiabilitiesField(choice) {
    this.typeDependentFieldTargets.forEach(e => {
      e.classList.toggle('d-none', !(e.dataset.for.split(',').includes(choice)))
    })
  }

  changeStatus(selection) {
    this.typeSelectionTargets.forEach(elem => {
      const parent = elem.closest('.d-none')
      // enable or disable
      if (parent && parent.dataset.for && parent.dataset.for.split(',').includes(selection)) {
        elem.disabled = false
      }
    })
    // show or hide
    this.showHideAssetsLiabilitiesField(selection)
  }

}
