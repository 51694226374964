import { Controller } from "@hotwired/stimulus"
import * as inputs from "../src/js/input_helper"
import * as utils from "../src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "tradeInType",
    "noTradeIn",
    "tradeInFromGlassGuide",
    "tradeInManual",
    "replacementCreditDetail",
    "replacementSwitch",
    "finalisationDate",
    "financeFields",
    "tradeInPayoutFields",
    "amountInput",
    "payoutInput",
    "refundInput",
    "netEquityOutput",
  ]

  connect() {
    this.handleTradeInType()
    this.handleReplacementSwitches()
  }

  tradeInTypeChanged() {
    this.handleTradeInType()
    this.element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  }

  hasTradeIn() {
    return(String(inputs.get_choice_from_radio_group(this.tradeInTypeTarget)).includes("yes"))
  }

  handleTradeInType() {
    const tradeInType = inputs.get_choice_from_radio_group(this.tradeInTypeTarget)
    const components = [ 
      this.noTradeInTarget,
      this.tradeInFromGlassGuideTarget,
      this.tradeInManualTarget
    ]
    if (this.hasTradeIn()) {
      utils.show(this.financeFieldsTarget)
    } else {
      utils.hide(this.financeFieldsTarget)
    }

    components.forEach(t => { if (t.dataset.value == tradeInType) { utils.show(t) } })
    components.forEach(t => { if (t.dataset.value != tradeInType) { utils.hide(t) } })

    this.showBalanceInput()
  }

  replacementSwitchChanged() {
    this.handleReplacementSwitches()
    this.element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  }

  hasReplacement() {
    return(["yes", "finalised"].includes(inputs.get_choice_from_radio_group(this.replacementSwitchTarget)))
  }

  replacementIsFinalised() {
    return(inputs.get_choice_from_radio_group(this.replacementSwitchTarget) == "finalised")
  }

  balanceIsRelevant() {
    return(this.hasReplacement() && !this.replacementIsFinalised())
  }

  handleReplacementSwitches() {
    if (this.hasReplacement()) {
      utils.show(this.replacementCreditDetailTarget)
      if (this.replacementIsFinalised()) {
        utils.show(this.finalisationDateTarget.parentElement)
      } else {
        utils.hide(this.finalisationDateTarget.parentElement)
      }
    } else {
      utils.hide(this.replacementCreditDetailTarget)
    }

    this.showBalanceInput()
    this.recalculateEquity()
  }

  showBalanceInput() {
    if (this.balanceIsRelevant() && this.hasTradeIn()) {
      utils.show(this.tradeInPayoutFieldsTarget)
    } else {
      utils.hide(this.tradeInPayoutFieldsTarget)
    }
  }

  recalculateEquity() {
    const amount = accounting.unformat(this.amountInputTarget.value)
    let payout = accounting.unformat(this.payoutInputTarget.value)
    let refund = accounting.unformat(this.refundInputTarget.value)

    if (!this.balanceIsRelevant()) {
      payout = 0.0
    }

    const max_refund = (amount > payout) ? (amount - payout).toFixed(2) : 0

    if (refund > max_refund) {
      alert(`The refund amount can't be more than $${String(max_refund)} (i.e. you can't have negative equity with a refund). We'll change that to the maximum amount allowable`)
      refund = max_refund
    }

    // update all fields
    this.amountInputTarget.value = accounting.formatMoney(amount)
    this.refundInputTarget.value = accounting.formatMoney(refund)
    if (this.balanceIsRelevant()) {
      this.payoutInputTarget.value = accounting.formatMoney(payout)
    } else {
      this.payoutInputTarget.value = ""
    }
    this.netEquityOutputTarget.value = accounting.formatMoney(amount - payout - refund)
  }

}
